// FIXME: This file is just an example, please take it as reference to make your own.

import React from 'react'
import { PUBLIC_URL } from '../../config/settings/environment'
import {
  Column,
  FacebookIcon,
  InstagramIcon,
  Item,
  Link,
  LinkedInIcon,
  List,
  Logo,
  Root,
  Row,
  Strong,
  TwitterIcon,
  YouTubeIcon
} from './FooterSection.styles'

/**
 * The FooterSection's container.
 */
const FooterSection = () => (
  <Root>
    <Row spacing={1}>
      <Column md={3} sm={12} xs={12}>
        <Logo src={`${PUBLIC_URL}/assets/images/logo_mercadopublico-white.svg`} />
      </Column>
      <Column md={3} sm={6} xs={12}>
        <List>
          <Item>
            <Strong>Dirección ChileCompra</Strong>
          </Item>
          <Item>Ministerio de Hacienda, Gobierno de Chile</Item>
          <Item>Monjitas 392 - Piso 8, Santiago de Chile.</Item>
          <Item>
            <Link href="https://www.chilecompra.cl/terminos-y-condiciones-de-uso/">Términos y condiciones</Link>
          </Item>
        </List>
      </Column>
      <Column md={2} sm={6} xs={12}>
        <List>
          <Item>
            <Strong>Sitios Relacionados</Strong>
          </Item>
          <Item>
            <Link href="http://api.mercadopublico.cl/">API Mercado Público</Link>
          </Item>
        </List>
      </Column>
      <Column md={2} sm={6} xs={12}>
        <List>
          <Item>
            <Strong>Mesa de ayuda</Strong>
          </Item>
          <Item>
            <Link href="tel:+6007000600">600 7000 600</Link>
          </Item>
          <Item>
            <Link href="tel:+56227677222">+56 44 2201003</Link>
          </Item>
          <Item>
            <Link href="https://ayuda.mercadopublico.cl/contacto/">Contacto</Link>
          </Item>
        </List>
      </Column>
      <Column md={2} sm={6} xs={12}>
        <List>
          <Item>
            <Strong>Síguenos en</Strong>
          </Item>
          <Item className="rrss-container">
            <Link href="https://www.facebook.com/pages/ChileCompra/284570349493">
              <FacebookIcon />
            </Link>
            <Link href="https://twitter.com/chilecompra">
              <TwitterIcon />
            </Link>
            <Link href="https://cl.linkedin.com/company/chilecompra">
              <LinkedInIcon />
            </Link>
            <Link href="https://www.youtube.com/user/chilecompratv">
              <YouTubeIcon />
            </Link>
            <Link href="https://www.instagram.com/chilecompra">
              <InstagramIcon />
            </Link>
          </Item>
        </List>
      </Column>
    </Row>
  </Root>
)

export default FooterSection
