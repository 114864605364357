// FIXME: This file is just an example, please take it as reference to make your own.

import React from 'react'
import PropTypes from 'prop-types'
import { Body, Footer, Header, Root, Section } from './BrandLayout.styles'

/**
 * The BrandLayout's component.
 */
const BrandLayout = props => {
  const { children, footer, header } = props

  return (
    <Root>
      {header && <Header>{header}</Header>}
      <Body container>
        <Section item md={12} sm={12} xs={12}>
          {children}
        </Section>
      </Body>
      {footer && <Footer>{footer}</Footer>}
    </Root>
  )
}

BrandLayout.defaultProps = {
  footer: null,
  header: null
}
BrandLayout.propTypes = {
  children: PropTypes.node.isRequired,
  footer: PropTypes.node,
  header: PropTypes.node
}

export default BrandLayout
