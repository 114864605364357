import React, { useEffect, useMemo, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import {
  Card,
  Typography,
  DatePicker,
  Input,
  InputSuffix,
  Switch,
  Button,
  useInput,
  useDatePicker,
  Snackbar,
  Toast,
  Spinner,
  Select,
  useSelect,
  TableCell,
  TableRow,
  Table,
  Pagination,
  Tag,
  CheckBox,
  Dialog,
  CircleWarningIcon,
  CircleSuccessIcon,
  CircleErrorIcon
} from '@chilecompra/react-kit/components'
import { useParams } from 'react-router-dom'
import {
  GridContainer,
  GridItem,
  Root,
  Title,
  Subtitle,
  ContainerSwitch,
  Content,
  CardText,
  Item,
  Textarea,
  Label
} from './AgreementAdjustment.styles'
import { checkInputErrorRequired, formatDate, checkErrorDniFormat, formatDNI } from '@chilecompra/react-kit/utils'
import { addYears, format } from 'date-fns'
import {
  onEditAgreementThunk,
  onGetAgreementThunk,
  onGetAgreementConfigThunk,
  onCreateAgreementThunk,
  onGetEconomicIndicatorsThunk,
  onGetRequestsThunk,
  onUpdateRequestStatusThunk,
  onGetTpErrosThunk,
  onDownloadRequestThunk
} from './AgreementAdjustment.actions'
import { Divider } from '@mui/material'
import { ADJUSTMENT_STATUS, REQUEST_AGREEMENT_STATUS, TAGS_STATUS_COLOR, MONTHS } from '../../config/settings/constants'
import { NumericFormat } from 'react-number-format'

/**
 * The AgreementAdjustment's container.
 */

const AgreementAdjustment = () => {
  const dispatch = useDispatch()
  const [openSnackbar, setOpenSnackbar] = useState('')
  const [enabled, setEnabled] = useState(false)
  const [configLoading, setConfigLoading] = useState(true)
  const [agreementLoading, setAgreementLoading] = useState(true)
  const [config, setConfig] = useState({})
  const [agreement, setAgreement] = useState({})
  const [selected, setSelected] = useState([])
  const [loading, setLoading] = useState(false)
  const [optionEditable, setOptionEditable] = useState('')
  const [statusUpdate, setStatusUpdate] = useState('')
  const [openSelected, setOpenSelected] = useState(false)
  const [lastAdjustment, setLastAdjustment] = useState(false)
  const [economicIndicatorsLoading, setEconomicIndicatorsLoading] = useState(true)
  const [economicIndicators, setEconomicIndicators] = useState({
    ineLastUpdate: '',
    bcLastUpdate: ''
  })
  const [adjustments, setAdjustments] = useState({
    requestList: [],
    page: 0,
    totalPages: 0
  })
  const [adjustmentsLoading, setAdjustmentsLoading] = useState(true)
  const [adjustmentsFilters, setAdjustmentsFilters] = useState({
    estadoReajuste: '',
    estadoSolicitud: '',
    rutProveedor: '',
    fechaSolicitud: '',
    fechaRespuesta: '',
    page: 1,
    idConvenioMarco: 0,
    search: '',
    sort: {
      column: 'nombreProducto',
      sort: 'asc'
    }
  })
  const { id } = useParams()
  const [overwriteValue, setOverwriteValue] = useState('')
  const [tpNotFound, setTpNotFound] = useState([])
  const [downloadLoading, setDownloadLoading] = useState(false)

  const toggleEnable = () => {
    setEnabled(!enabled)
  }
  const validDays = function (value) {
    return value < 0 ? 'No puede ser un número negativo.' : value > 365 ? 'No puede ingresar más de 1 año' : ''
  }

  const validPercentajes = function (value) {
    return value < 0 ? 'No puede ser un número negativo.' : value > 100 ? 'Máximo 100%' : ''
  }

  const validInteger = function (value) {
    return !Number.isInteger(Number(value)) ? 'Solo se admite numeros enteros.' : ''
  }

  const requiredNumber = function (value) {
    return value === '' ? 'Este campo es obligatorio.' : ''
  }

  const generateDate = function (time) {
    const _time = new Date(time)
    return `${_time.getDate()}/${_time.getMonth() + 1}/${_time.getFullYear()}`
  }

  const {
    error: firstReadjustmentDateError,
    value: firstReadjustmentDate,
    onChange: handleChangeFirstReadjustmentDate
  } = useDatePicker({
    maxDate: addYears(new Date(), 1),
    initialValue: config.firstReadjustmentDate ? new Date(config.firstReadjustmentDate) : null,
    overwriteCallback: () => (config.firstReadjustmentDate ? new Date(config.firstReadjustmentDate) : null)
  })

  const {
    error: frequencyError,
    value: frequency,
    onChange: handleChangeFrequency
  } = useInput({
    errorCallbacks: [validDays, validInteger],
    initialValue: config.frequency || '',
    overwriteCallback: () => config.frequency || ''
  })

  const {
    error: percentError,
    value: percent,
    onChange: handleChangePercent
  } = useInput({
    errorCallbacks: [requiredNumber, validPercentajes, validInteger],
    initialValue: Number(config.percent) >= 0 ? config.percent : '',
    overwriteCallback: () => (Number(config.percent) >= 0 ? config.percent : '')
  })

  const {
    error: vigencyError,
    value: vigency,
    onChange: handleChangeVigency
  } = useInput({
    errorCallbacks: [requiredNumber, validDays, validInteger],
    initialValue: config.vigency || '',
    overwriteCallback: () => config.vigency || ''
  })

  const {
    error: minPercentError,
    value: minPercent,
    onChange: handleChangeMinPercent
  } = useInput({
    errorCallbacks: [checkInputErrorRequired(), validPercentajes],
    initialValue: config.minPercent || '',
    overwriteCallback: () => config.minPercent || ''
  })

  const {
    error: maxPercentError,
    value: maxPercent,
    onChange: handleChangeMaxPercent
  } = useInput({
    errorCallbacks: [checkInputErrorRequired(), validPercentajes],
    initialValue: config.maxPercent || '',
    overwriteCallback: () => config.maxPercent || ''
  })

  const handleSubmit = event => {
    event.preventDefault()
    setOpenSnackbar('')
    if (config.id) {
      dispatch(
        onEditAgreementThunk({
          successCallback: () => {
            setOpenSnackbar('success')
            getConfig()
          },
          errorCallback: () => {
            setOpenSnackbar('error')
          },
          frequency: parseInt(frequency),
          isEnabled: enabled ? 1 : 0,
          firstReadjustmentDate: firstReadjustmentDate
            ? formatDate(firstReadjustmentDate, 'yyyy-MM-dd')
            : firstReadjustmentDate,
          percent: Number(percent),
          vigency: parseInt(vigency),
          minPercent: parseInt(minPercent),
          maxPercent: parseInt(maxPercent),
          convenioMarcoId: parseInt(id),
          id: config.id
        })
      )
    } else {
      dispatch(
        onCreateAgreementThunk({
          successCallback: () => {
            setOpenSnackbar('success')
            getConfig()
          },
          errorCallback: () => {
            setOpenSnackbar('error')
          },
          frequency: parseInt(frequency),
          isEnabled: enabled ? 1 : 0,
          firstReadjustmentDate: firstReadjustmentDate
            ? formatDate(firstReadjustmentDate, 'yyyy-MM-dd')
            : firstReadjustmentDate,
          percent: Number(percent),
          vigency: parseInt(vigency),
          minPercent: parseInt(minPercent),
          maxPercent: parseInt(maxPercent),
          convenioMarcoId: parseInt(id)
        })
      )
    }
  }

  const disableSubmit = useMemo(
    () =>
      frequency < 0 ||
      frequency > 365 ||
      !percent ||
      percent < 0 ||
      percent > 100 ||
      !minPercent ||
      minPercent < 0 ||
      minPercent > 100 ||
      !maxPercent ||
      maxPercent < 0 ||
      maxPercent > 100 ||
      vigency < 0 ||
      vigency > 365 ||
      !Number.isInteger(Number(percent)),
    [
      frequency,
      frequencyError,
      firstReadjustmentDate,
      firstReadjustmentDateError,
      percent,
      percentError,
      vigency,
      vigencyError,
      minPercent,
      minPercentError,
      maxPercent,
      maxPercentError
    ]
  )

  const setValues = function (value) {
    if (value === null) {
      setConfig({
        convenioMarcoId: id
      })
    } else if (!value?.code) {
      setConfig(value)
    }

    if (value?.isEnabled > 0) {
      setEnabled(true)
    } else {
      setEnabled(false)
    }
  }

  const getAdjustmentcolor = function (status) {
    return (
      TAGS_STATUS_COLOR.find(e => {
        return e.value.toLowerCase() === status.toLowerCase()
      })?.color ?? 'default'
    )
  }

  const getAdjustmentText = function (text) {
    return (
      REQUEST_AGREEMENT_STATUS.find(e => {
        return e.value.toLowerCase() === text.toLowerCase()
      })?.name ?? '-'
    )
  }

  const {
    error: adjustmentStatusError,
    value: adjustmentStatus,
    onChange: handleChangeAdjustmentStatus,
    onReset: resetAdjustmentStatus
  } = useSelect({
    overwriteCallback: () => adjustmentsFilters.estadoReajuste || ''
  })

  const {
    error: requestStatusError,
    value: requestStatus,
    onChange: handleChangeRequestStatus,
    onReset: resetRequestStatus
  } = useSelect({
    overwriteCallback: () => adjustmentsFilters.estadoSolicitud || ''
  })

  const {
    error: rutError,
    value: rut,
    onChange: handleChangeRut,
    onReset: resetRut
  } = useInput({
    formatCallbacks: [formatDNI],
    errorCallbacks: [checkErrorDniFormat()].filter(Boolean),
    overwriteCallback: () => adjustmentsFilters.rut || ''
  })

  const {
    error: requestDateError,
    value: requestDate,
    onChange: handleChangeRequestDate,
    onReset: resetRequestDate
  } = useDatePicker({
    overwriteCallback: () => (adjustmentsFilters.requestDate ? new Date(adjustmentsFilters.requestDate) : null)
  })

  const {
    error: responseDateError,
    value: responseDate,
    onChange: handleChangeResponseDate,
    onReset: resetResponseDate
  } = useDatePicker({
    overwriteCallback: () => (adjustmentsFilters.responseDate ? new Date(adjustmentsFilters.responseDate) : null)
  })

  const {
    error: searchError,
    value: search,
    onChange: handleSearch
  } = useSelect({
    overwriteCallback: () => adjustmentsFilters.search || ''
  })

  const isChecked = function (id) {
    return selected.find(function (element) {
      return element.id === id
    })
  }

  const handleChange = e => {
    const { value, checked } = e.target
    if (checked) {
      setSelected(prev => [
        ...prev,
        adjustments.requestList.find(adjustment => Number(adjustment.id) === Number(value))
      ])
    } else {
      setSelected(prev => prev.filter(x => x.id !== Number(value)))
    }
  }

  const handleChangeHead = e => {
    const { checked } = e.target
    if (checked) {
      setSelected(
        adjustments.requestList.filter(
          adjustment =>
            adjustment.reajusteStatus.toLowerCase() === 'pendiente' &&
            (adjustment.solicitudStatus.toLowerCase() === 'pendiente' ||
              adjustment.solicitudStatus.toLowerCase() === 'error')
        )
      )
    } else {
      setSelected([])
    }
  }

  const { error: commentRejectError, value: commentReject } = useSelect({
    initialValue: '',
    overwriteCallback: () => overwriteValue
  })

  const handleChangeCommentReject = useCallback(
    e => {
      setOverwriteValue(e.target.value)
    },
    [overwriteValue]
  )

  const approvedAdjustment = useCallback(() => {
    dispatch(
      onUpdateRequestStatusThunk({
        callbackSuccess: () => {
          setLoading(false)
          setStatusUpdate('success')
          setOpenSelected(false)
          getAdjustmentRequest()
          setSelected([])
        },
        callbackError: () => {
          setLoading(false)
          setStatusUpdate('error')
          setOpenSelected(false)
        },
        loading: () => {
          setLoading(true)
        },
        payload: {
          data: selected.map(_adjustment => ({
            requestId: _adjustment.id,
            solicitudStatus: 'VIGENTE',
            reajusteStatus: 'VIGENTE',
            comment: ''
          }))
        }
      })
    )
  }, [selected])

  const rejectAdjustment = useCallback(() => {
    dispatch(
      onUpdateRequestStatusThunk({
        callbackSuccess: () => {
          setLoading(false)
          setStatusUpdate('success')
          setOpenSelected(false)
          setOverwriteValue('')
          getAdjustmentRequest()
          setSelected([])
        },
        callbackError: () => {
          setLoading(false)
          setStatusUpdate('error')
          setOpenSelected(false)
        },
        loading: () => {
          setLoading(true)
        },
        payload: {
          data: selected.map(_adjustment => ({
            requestId: _adjustment.id,
            solicitudStatus: 'RECHAZADO',
            reajusteStatus: 'RECHAZADO',
            comment: overwriteValue
          }))
        }
      })
    )
  }, [selected, overwriteValue])

  const loadEconomicIndicators = function () {
    dispatch(
      onGetEconomicIndicatorsThunk({
        callback: w => {
          setEconomicIndicatorsLoading(false)
          setEconomicIndicators(w)
        },
        loading: () => {
          setEconomicIndicatorsLoading(true)
        }
      })
    )
  }

  const downloadAdjustmentRequest = useCallback(() => {
    const data = { ...adjustmentsFilters }
    dispatch(
      onDownloadRequestThunk({
        callback: w => {
          const url = window.URL.createObjectURL(new Blob([w]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'Historico.csv')
          document.body.appendChild(link)
          link.click()
          link.remove()
          setDownloadLoading(false)
        },
        loading: () => {
          setDownloadLoading(true)
        },
        payload: data
      })
    )
  }, [adjustmentsFilters])

  const handleChangeOrderRequest = useCallback(
    (column, sort = 'asc') => {
      setAdjustmentsFilters(prevState => {
        const object = Object.assign({}, prevState)
        object.sort.column = column
        object.sort.sort = sort
        return { object }
      })
      assigFiltersToRequest()
    },
    [adjustmentsFilters]
  )

  const getAdjustmentRequest = function (payload = adjustmentsFilters) {
    dispatch(
      onGetRequestsThunk({
        callback: w => {
          if (w) {
            setAdjustments(w)
          }
          setAdjustmentsLoading(false)
        },
        loading: () => {
          setAdjustmentsLoading(true)
        },
        payload
      })
    )
  }

  const filterGetAdjustment = function (params) {
    const _params = {
      estadoReajuste: adjustmentsFilters.estadoReajuste,
      estadoSolicitud: adjustmentsFilters.estadoSolicitud,
      rutProveedor: adjustmentsFilters.rutProveedor,
      fechaSolicitud: adjustmentsFilters.fechaSolicitud,
      fechaRespuesta: adjustmentsFilters.fechaRespuesta,
      page: params?.page ?? adjustmentsFilters.page,
      sort: adjustmentsFilters.sort,
      idConvenioMarco: Number(id),
      nombreProducto: adjustmentsFilters.nombreProducto,
      idProducto: 0
    }
    setAdjustmentsFilters(_params)
    getAdjustmentRequest(_params)
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  const assigFiltersToRequest = useCallback(() => {
    const params = {
      estadoSolicitud: requestStatus ?? '',
      estadoReajuste: adjustmentStatus ?? '',
      rutProveedor: rut ?? '',
      fechaSolicitud: requestDate ? formatDate(requestDate, 'yyyy-MM-dd') : '',
      fechaRespuesta: responseDate ? formatDate(responseDate, 'yyyy-MM-dd') : '',
      page: 1,
      sort: adjustmentsFilters.sort,
      idConvenioMarco: Number(id),
      idProducto: 0,
      nombreProducto: search
    }
    setAdjustmentsFilters(params)
    getAdjustmentRequest(params)
  }, [requestStatus, adjustmentsFilters, adjustmentStatus, requestDate, responseDate, rut, search])

  const getConfig = function () {
    dispatch(
      onGetAgreementConfigThunk({
        callback: w => {
          setConfigLoading(false)
          setValues(w)
        },
        loading: () => {
          setConfigLoading(true)
        },
        id
      })
    )
  }

  const getTPErrors = function () {
    dispatch(
      onGetTpErrosThunk({
        callback: w => {
          if (w) {
            setTpNotFound(w)
          }
        },
        id
      })
    )
  }

  const resetFilters = function () {
    resetAdjustmentStatus()
    resetRequestStatus()
    resetRut()
    resetRequestDate()
    resetResponseDate()
  }

  useEffect(() => {
    getConfig()
    dispatch(
      onGetAgreementThunk({
        callback: w => {
          setAgreementLoading(false)
          if (w) {
            setAgreement(w)
          }
        },
        loading: () => {
          setAgreementLoading(true)
        },
        id
      })
    )
    loadEconomicIndicators()
    assigFiltersToRequest()
    getTPErrors()
  }, [])

  useEffect(() => {
    const dateToBeCheckOut = new Date(config.firstReadjustmentDate)
    dateToBeCheckOut.setDate(dateToBeCheckOut.getDate() + config.frequency)
    setLastAdjustment(new Date() > dateToBeCheckOut)
  }, [config, lastAdjustment])

  return (
    <Root>
      {agreementLoading || configLoading ? (
        <Spinner />
      ) : !agreement.idConvenioMarco ? (
        <Toast severity="error">Ha ocurrido un error, vuelva a intentar.</Toast>
      ) : (
        <Root>
          <GridContainer>
            <GridItem xs={12}>
              <Title>Convenio Marco De {agreement.nombreConvenioMarco}</Title>
              <Typography variant="h4">ID Licitación: {agreement.nroLicitacionPublica}</Typography>
            </GridItem>
          </GridContainer>
          <Content onSubmit={handleSubmit}>
            <GridContainer>
              <GridItem xs={12}>
                <Subtitle>Ajustes del convenio marco</Subtitle>
                <Card enableBorder className="cardTrasparent ajuste">
                  <GridContainer>
                    <GridItem padding="0 5px" xs={12} sm={6} md={4} lg={3}>
                      <DatePicker
                        label="Fecha de primer reajuste ordinario"
                        placeholder="12/07/2021"
                        value={firstReadjustmentDate}
                        error={firstReadjustmentDateError}
                        onChange={handleChangeFirstReadjustmentDate}
                        maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 1))}
                      />
                    </GridItem>
                    <GridItem padding="0 5px" xs={12} sm={6} md={4} lg={3}>
                      <Input
                        label="frecuencia"
                        placeholder="5"
                        suffix={<InputSuffix width="60px">dias</InputSuffix>}
                        value={frequency}
                        error={frequencyError}
                        onChange={handleChangeFrequency}
                        type="number"
                      />
                    </GridItem>
                    <GridItem padding="0 5px" xs={12} sm={6} md={4} lg={3}>
                      <Input
                        label="porcentaje de corte"
                        placeholder="5"
                        suffix={<InputSuffix width="60px">%</InputSuffix>}
                        value={percent}
                        error={percentError}
                        onChange={handleChangePercent}
                        type="number"
                        required
                      />
                    </GridItem>
                    <GridItem padding="0 5px" xs={12} sm={6} md={4} lg={3}>
                      <Input
                        label="vigencia reajuste extraordinario"
                        placeholder="5"
                        suffix={<InputSuffix width="60px">dias</InputSuffix>}
                        value={vigency}
                        error={vigencyError}
                        onChange={handleChangeVigency}
                        type="number"
                        required
                      />
                    </GridItem>
                    <GridItem padding="0 5px" xs={12} sm={6} md={4} lg={3}>
                      <Input
                        label="porcentaje mínimo de aplicación"
                        placeholder="5"
                        suffix={<InputSuffix width="60px">%</InputSuffix>}
                        value={minPercent}
                        error={minPercentError}
                        onChange={handleChangeMinPercent}
                        type="number"
                        required
                      />
                    </GridItem>
                    <GridItem padding="0 5px" xs={12} sm={6} md={4} lg={3}>
                      <Input
                        label="porcentaje máximo de aplicación"
                        placeholder="5"
                        suffix={<InputSuffix width="60px">%</InputSuffix>}
                        value={maxPercent}
                        error={maxPercentError}
                        onChange={handleChangeMaxPercent}
                        type="number"
                        required
                      />
                    </GridItem>
                    <GridItem padding="0 5px" xs={12} sm={6} md={4} lg={3}>
                      <ContainerSwitch>
                        <Switch checked={enabled} labelRight="Habilitado" onChange={toggleEnable} />
                      </ContainerSwitch>
                    </GridItem>
                    <GridItem padding="0 5px" xs={12} sm={6} md={4} lg={3}>
                      <ContainerSwitch>
                        <Button color="primary" margin="5px" disabled={disableSubmit} type="submit" variant="contained">
                          Guardar
                        </Button>
                      </ContainerSwitch>
                    </GridItem>
                  </GridContainer>
                </Card>
              </GridItem>
            </GridContainer>
            <Card margin="15px 0" className="card-alert">
              <CardText>
                Última actualización de precios del INE:
                {economicIndicatorsLoading ? (
                  <div
                    style={{
                      marginLeft: '10px',
                      display: 'inline',
                      verticalAlign: 'middle'
                    }}
                  >
                    <Spinner size={20} />
                  </div>
                ) : economicIndicators.ineLastUpdate ? (
                  <CardText className="textEconomicIndicator">
                    {format(new Date(economicIndicators.ineLastUpdate), 'dd/MM/yyyy')}
                  </CardText>
                ) : (
                  <CardText className="textEconomicIndicator error"> Error </CardText>
                )}
              </CardText>
              <Divider orientation="vertical" flexItem />
              <CardText>
                Dólar Banco Central:
                {economicIndicatorsLoading ? (
                  <div
                    style={{
                      marginLeft: '10px',
                      display: 'inline',
                      verticalAlign: 'middle'
                    }}
                  >
                    <Spinner size={20} />
                  </div>
                ) : economicIndicators.ineLastUpdate ? (
                  <CardText className="textEconomicIndicator">
                    {MONTHS[new Date(economicIndicators.bcLastUpdate).getMonth()]}
                    {format(new Date(economicIndicators.bcLastUpdate), ' yyyy')}
                  </CardText>
                ) : (
                  <CardText className="textEconomicIndicator error"> Error </CardText>
                )}
              </CardText>
            </Card>
            {(tpNotFound && tpNotFound.length > 0) || lastAdjustment ? (
              <Card className="errorsTpCard" padding="24px 30px" margin="15px 0" color="error">
                {tpNotFound.length > 0 ? (
                  <CardText>
                    Existen 1 o más productos con <CardText> errores de catalogación </CardText> :
                  </CardText>
                ) : (
                  <CardText> El último reajuste ordinario no ha sido cargado </CardText>
                )}
                {tpNotFound.map((element, index) => (
                  <Item key={index}>{element}</Item>
                ))}
              </Card>
            ) : (
              ''
            )}
          </Content>
          <Content>
            <Subtitle> Listado de reajustes históricos</Subtitle>
            <Card enableBorder className="cardTrasparent">
              <GridContainer className="containerFilterInputs">
                <GridItem padding="0 5px">
                  <Select
                    error={adjustmentStatusError}
                    label="estado del reajuste"
                    options={ADJUSTMENT_STATUS}
                    placeholder="Todos"
                    value={adjustmentStatus}
                    onChange={handleChangeAdjustmentStatus}
                  />
                </GridItem>
                <GridItem padding="0 5px">
                  <Select
                    error={requestStatusError}
                    label="estado solicitud"
                    options={REQUEST_AGREEMENT_STATUS.filter(e => {
                      return e.value !== 'No vigente'
                    })}
                    placeholder="Todos"
                    value={requestStatus}
                    onChange={handleChangeRequestStatus}
                  />
                </GridItem>
                <GridItem padding="0 5px">
                  <Input
                    label="RUT Proveedor"
                    value={rut}
                    error={rutError}
                    onChange={handleChangeRut}
                    placeholder="00.000.000-0"
                    maxLength="12"
                  />
                </GridItem>
                <GridItem padding="0 5px">
                  <DatePicker
                    label="Fecha solicitud"
                    placeholder="12/07/2021"
                    value={requestDate}
                    error={requestDateError}
                    onChange={handleChangeRequestDate}
                    maxDate={new Date()}
                  />
                </GridItem>
                <GridItem padding="0 5px">
                  <DatePicker
                    label="Fecha Respuesta"
                    placeholder="12/07/2021"
                    value={responseDate}
                    error={responseDateError}
                    onChange={handleChangeResponseDate}
                    maxDate={new Date()}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer className="containerSubmitButtonFilter">
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'end'
                  }}
                >
                  <div
                    style={{
                      marginRight: '10px'
                    }}
                  >
                    <Button
                      onClick={() => resetFilters(true)}
                      variant="outlined"
                      style={{
                        margin: '5px'
                      }}
                    >
                      Limpiar
                    </Button>
                  </div>
                  <Button color="primary" margin="5px" variant="contained" onClick={() => assigFiltersToRequest()}>
                    Buscar
                  </Button>
                </div>
              </GridContainer>
            </Card>
            <GridContainer className="containerSearch">
              <GridItem padding="0 5px" sm={4} md={4} lg={3}>
                <Input
                  label="Buscar por nombre de producto"
                  value={search}
                  error={searchError}
                  onChange={handleSearch}
                  placeholder="Nombre producto"
                  suffix={
                    <InputSuffix width="60px" onClick={() => assigFiltersToRequest()}>
                      Buscar
                    </InputSuffix>
                  }
                />
              </GridItem>
            </GridContainer>
            <GridContainer className="containerTable">
              <Table margin="15px 0" loading={adjustmentsLoading}>
                <TableRow enableHead>
                  <TableCell>
                    <CheckBox
                      onChange={handleChangeHead}
                      checked={
                        adjustments.requestList.filter(
                          adjustment =>
                            adjustment.reajusteStatus.toLowerCase() === 'pendiente' &&
                            (adjustment.solicitudStatus.toLowerCase() === 'pendiente' ||
                              adjustment.solicitudStatus.toLowerCase() === 'error')
                        ).length === selected.length
                      }
                    />
                  </TableCell>
                  <TableCell
                    className={`orderableCell order${capitalizeFirstLetter(adjustmentsFilters.sort.sort)} ${
                      adjustmentsFilters.sort.column === 'reajusteStatus' ? 'active' : ''
                    }`}
                    onClick={() =>
                      handleChangeOrderRequest(
                        'reajusteStatus',
                        adjustmentsFilters.sort.column !== 'reajusteStatus'
                          ? 'asc'
                          : adjustmentsFilters.sort.sort === 'asc'
                          ? 'desc'
                          : 'asc'
                      )
                    }
                  >
                    Estado Reajuste
                  </TableCell>
                  <TableCell
                    className={`orderableCell order${capitalizeFirstLetter(adjustmentsFilters.sort.sort)} ${
                      adjustmentsFilters.sort.column === 'solicitudStatus' ? 'active' : ''
                    }`}
                    onClick={() =>
                      handleChangeOrderRequest(
                        'solicitudStatus',
                        adjustmentsFilters.sort.column !== 'solicitudStatus'
                          ? 'asc'
                          : adjustmentsFilters.sort.sort === 'asc'
                          ? 'desc'
                          : 'asc'
                      )
                    }
                  >
                    Estado Solicitud
                  </TableCell>
                  <TableCell> RUT Proveedor </TableCell>
                  <TableCell
                    className={`orderableCell order${capitalizeFirstLetter(adjustmentsFilters.sort.sort)} ${
                      adjustmentsFilters.sort.column === 'idProducto' ? 'active' : ''
                    }`}
                    onClick={() =>
                      handleChangeOrderRequest(
                        'idProducto',
                        adjustmentsFilters.sort.column !== 'idProducto'
                          ? 'asc'
                          : adjustmentsFilters.sort.sort === 'asc'
                          ? 'desc'
                          : 'asc'
                      )
                    }
                  >
                    ID Producto
                  </TableCell>
                  <TableCell
                    className={`orderableCell order${capitalizeFirstLetter(adjustmentsFilters.sort.sort)} ${
                      adjustmentsFilters.sort.column === 'nombreProducto' ? 'active' : ''
                    }`}
                    onClick={() =>
                      handleChangeOrderRequest(
                        'nombreProducto',
                        adjustmentsFilters.sort.column !== 'nombreProducto'
                          ? 'asc'
                          : adjustmentsFilters.sort.sort === 'asc'
                          ? 'desc'
                          : 'asc'
                      )
                    }
                  >
                    Producto
                  </TableCell>
                  <TableCell
                    className={`orderableCell order${capitalizeFirstLetter(adjustmentsFilters.sort.sort)} ${
                      adjustmentsFilters.sort.column === 'precioRegular' ? 'active' : ''
                    }`}
                    onClick={() =>
                      handleChangeOrderRequest(
                        'precioRegular',
                        adjustmentsFilters.sort.column !== 'precioRegular'
                          ? 'asc'
                          : adjustmentsFilters.sort.sort === 'asc'
                          ? 'desc'
                          : 'asc'
                      )
                    }
                  >
                    Precio Regular
                  </TableCell>
                  <TableCell
                    className={`orderableCell order${capitalizeFirstLetter(adjustmentsFilters.sort.sort)} ${
                      adjustmentsFilters.sort.column === 'precioReajustado' ? 'active' : ''
                    }`}
                    onClick={() =>
                      handleChangeOrderRequest(
                        'precioReajustado',
                        adjustmentsFilters.sort.column !== 'precioReajustado'
                          ? 'asc'
                          : adjustmentsFilters.sort.sort === 'asc'
                          ? 'desc'
                          : 'asc'
                      )
                    }
                  >
                    Precio Reajustado
                  </TableCell>
                  <TableCell> Porcentaje reajuste </TableCell>
                  <TableCell> Fecha solicitud </TableCell>
                  <TableCell> Fecha respuesta </TableCell>
                  <TableCell> Vigencia </TableCell>
                  <TableCell> Observaciones </TableCell>
                </TableRow>
                {adjustments?.requestList?.length > 0 ? (
                  adjustments.requestList.map((adjustment, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        {adjustment.reajusteStatus.toLowerCase() === 'pendiente' &&
                        (adjustment.solicitudStatus.toLowerCase() === 'pendiente' ||
                          adjustment.solicitudStatus.toLowerCase() === 'error') ? (
                          <CheckBox onChange={handleChange} checked={isChecked(adjustment.id)} value={adjustment.id} />
                        ) : (
                          ''
                        )}
                      </TableCell>
                      <TableCell> {adjustment.reajusteStatus} </TableCell>
                      <TableCell>
                        <Tag
                          text={getAdjustmentText(adjustment.solicitudStatus)}
                          color={getAdjustmentcolor(adjustment.solicitudStatus)}
                        />
                      </TableCell>
                      <TableCell> {adjustment.rutProveedor} </TableCell>
                      <TableCell> {adjustment.idProducto} </TableCell>
                      <TableCell> {adjustment.nombreProducto} </TableCell>
                      <TableCell
                        style={{
                          textAlign: 'right'
                        }}
                      >
                        <NumericFormat
                          displayType="text"
                          value={adjustment.precioRegular}
                          decimalSeparator=","
                          prefix="$"
                          thousandSeparator="."
                        />
                      </TableCell>
                      <TableCell
                        style={{
                          textAlign: 'right'
                        }}
                      >
                        <NumericFormat
                          displayType="text"
                          value={adjustment.precioReajustado}
                          decimalSeparator=","
                          prefix="$"
                          thousandSeparator="."
                        />
                      </TableCell>
                      <TableCell
                        style={{
                          textAlign: 'right'
                        }}
                      >
                        <NumericFormat
                          displayType="text"
                          value={adjustment.porcentajeReajuste}
                          suffix="%"
                          decimalSeparator=","
                        />
                      </TableCell>
                      <TableCell
                        style={{
                          textAlign: 'right'
                        }}
                      >
                        {generateDate(adjustment.fechaSolicitud)}
                      </TableCell>
                      <TableCell
                        style={{
                          textAlign: 'right'
                        }}
                      >
                        {generateDate(adjustment.fechaRespuesta)}
                      </TableCell>
                      <TableCell
                        style={{
                          textAlign: 'right'
                        }}
                      >
                        {generateDate(adjustment.vigencia)}
                      </TableCell>
                      <TableCell> {adjustment.observaciones} </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={13} textAlign="center">
                      <div
                        style={{
                          width: '100%',
                          textAlign: 'center',
                          opacity: '0.8'
                        }}
                      >
                        No hay resultados
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </Table>
            </GridContainer>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'end',
                marginBottom: '15px',
                marginTop: '5px'
              }}
            >
              <Pagination
                count={adjustments.totalPages}
                disableFirstButton
                disableLastButton
                onChange={(event, page) => {
                  filterGetAdjustment({ page })
                }}
                page={adjustments.page}
                variant="contained"
              />
            </div>
            <div
              className="containerButtons"
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between'
              }}
            >
              <div>
                <ContainerSwitch>
                  <Button
                    color="primary"
                    margin="5px"
                    variant="text"
                    onClick={() => downloadAdjustmentRequest()}
                    loading={downloadLoading}
                  >
                    Descargar datos
                  </Button>
                </ContainerSwitch>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'end'
                }}
              >
                <div
                  style={{
                    marginRight: '10px'
                  }}
                >
                  <Button
                    color="primary"
                    onClick={() => {
                      setOpenSelected(true)
                      setOptionEditable('reject')
                    }}
                    variant="outlined"
                    className="marginR"
                    disabled={selected.length === 0}
                  >
                    Rechazar
                  </Button>
                </div>
                <Button
                  color="primary"
                  onClick={() => {
                    setOpenSelected(true)
                    setOptionEditable('approved')
                  }}
                  disabled={
                    selected.length === 0 ||
                    selected.filter(adjustment => adjustment.solicitudStatus.toLowerCase() === 'error').length > 0
                  }
                >
                  Aprobar
                </Button>
              </div>
            </div>
          </Content>
          <Snackbar duration={2} open={openSnackbar === 'success'}>
            <Toast disableIcon severity="success">
              Convenio marco actualizado exitosamente
            </Toast>
          </Snackbar>
          <Snackbar duration={2} open={openSnackbar === 'error'}>
            <Toast disableIcon severity="error">
              Ha ocurrido un error, vuelva a intentar.
            </Toast>
          </Snackbar>
          <Dialog
            open={openSelected}
            disableBackdropClick
            onClose={() => {
              if (!loading) {
                setOpenSelected(false)
              }
            }}
          >
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                padding: '20px'
              }}
            >
              <CircleWarningIcon
                style={{
                  fontSize: '64px'
                }}
              />
              <div
                style={{
                  margin: '16px 0 0',
                  textAlign: 'center',
                  width: '100%'
                }}
              >
                <Typography fontWeight="bold" variant="h4">
                  {optionEditable === 'approved'
                    ? 'Estás a punto de aprobar un reajuste de precio'
                    : 'Estás a punto de rechazar un reajuste de precio'}
                </Typography>
                <Typography margin="16px 0 0" variant="body2">
                  Antes de finalizar, revisa el detalle de los precios que serán reajustados.
                </Typography>
              </div>
              <GridContainer className="containerTableModal">
                <Table margin="15px 0">
                  <TableRow enableHead>
                    <TableCell> ID Producto </TableCell>
                    <TableCell> RUT Proveedor </TableCell>
                    <TableCell> Precio Regular </TableCell>
                    <TableCell> Precio Reajustado </TableCell>
                  </TableRow>
                  {selected?.map((adjustment, index) => (
                    <TableRow key={index}>
                      <TableCell> {adjustment.idProducto} </TableCell>
                      <TableCell> {adjustment.rutProveedor} </TableCell>
                      <TableCell
                        style={{
                          textAlign: 'right'
                        }}
                      >
                        <NumericFormat
                          displayType="text"
                          value={adjustment.precioRegular}
                          decimalSeparator=","
                          prefix="$"
                          thousandSeparator="."
                        />
                      </TableCell>
                      <TableCell
                        style={{
                          textAlign: 'right'
                        }}
                      >
                        <NumericFormat
                          displayType="text"
                          value={adjustment.precioReajustado}
                          decimalSeparator=","
                          prefix="$"
                          thousandSeparator="."
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </Table>
              </GridContainer>
              {optionEditable === 'approved' ? (
                ''
              ) : (
                <div
                  style={{
                    width: '100%'
                  }}
                >
                  <Label>observación</Label>
                  <Textarea
                    value={commentReject}
                    error={commentRejectError}
                    onChange={e => handleChangeCommentReject(e)}
                  />
                </div>
              )}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  marginTop: '20px'
                }}
              >
                {loading ? (
                  <Spinner />
                ) : optionEditable === 'approved' ? (
                  <Button onClick={() => approvedAdjustment()} color="success">
                    Aprobar reajuste
                  </Button>
                ) : (
                  <Button onClick={() => rejectAdjustment()} color="error" variant="outlined">
                    Rechazar reajuste
                  </Button>
                )}
              </div>
            </div>
          </Dialog>
          <Dialog
            open={statusUpdate}
            disableBackdropClick
            onClose={() => {
              if (!loading) {
                setStatusUpdate(false)
              }
            }}
          >
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                padding: '20px'
              }}
            >
              {statusUpdate === 'success' ? (
                <CircleSuccessIcon
                  style={{
                    fontSize: '64px'
                  }}
                />
              ) : (
                <CircleErrorIcon
                  style={{
                    fontSize: '64px'
                  }}
                />
              )}

              <div
                style={{
                  margin: '16px 0 0',
                  textAlign: 'center',
                  width: '100%'
                }}
              >
                {statusUpdate === 'success' ? (
                  <Typography fontWeight="bold" variant="h4">
                    {optionEditable === 'approved'
                      ? 'Reajuste de precio aplicado satisfactoriamente'
                      : 'Reajuste de precio rechazado satisfactoriamente'}
                  </Typography>
                ) : (
                  <Typography fontWeight="bold" variant="h4">
                    {optionEditable === 'approved'
                      ? 'El reajuste de precio no pudo ser aplicado'
                      : 'El reajuste no pudo ser rechazado'}
                  </Typography>
                )}
                {statusUpdate === 'error' ? (
                  <Typography margin="16px 0 0" variant="body2">
                    {optionEditable === 'approved'
                      ? 'Lo sentimos, no pudimos aplicar el reajuste, por favor inténtalo en unos minutos más.'
                      : 'Lo sentimos, no pudimos rechazado el reajuste, por favor inténtalo en unos minutos más.'}
                  </Typography>
                ) : (
                  ''
                )}
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  marginTop: '20px'
                }}
              >
                <Button onClick={() => setStatusUpdate(false)}> Cerrar </Button>
              </div>
            </div>
          </Dialog>
        </Root>
      )}
    </Root>
  )
}
export default AgreementAdjustment
