// FIXME: This file is just an example, please take it as reference to make your own.

import React, { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider as ReduxProvider } from 'react-redux'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import { initializeLogger, ThemeProvider, inIframe, observerResizeIframe } from '@chilecompra/react-kit'
import { REACT_APP_ENVIRONMENT, REACT_APP_MERCADO_PUBLICO_BASE_URL } from './config/settings/environment'
import storeSingleton from './config/store/store'
import Routing from './Routing'

initializeLogger({ env: REACT_APP_ENVIRONMENT === 'prod' ? 'prod' : 'dev' })

const store = storeSingleton()
const container = document.getElementById('__root')
const root = createRoot(container)

if (inIframe()) {
  observerResizeIframe(REACT_APP_MERCADO_PUBLICO_BASE_URL, { childList: true })
}

root.render(
  <StrictMode>
    <ReduxProvider store={store}>
      <ThemeProvider>
        <Routing />
      </ThemeProvider>
    </ReduxProvider>
  </StrictMode>
)
