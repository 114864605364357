// FIXME: This file is just an example, please take it as reference to make your own.

import React from 'react'
import { PUBLIC_URL } from '../config/settings/environment'
import BrandLayout from '../components/Layouts/BrandLayout'
import FooterSectionContainer from '../containers/FooterSection/FooterSection.container'
import HeaderSectionContainer from '../containers/HeaderSection/HeaderSection.container'
import { Image404, Root, Text } from './NotFound.styles'

/**
 * The NotFound' page.
 */
const NotFound = () => (
  <BrandLayout footer={<FooterSectionContainer />} header={<HeaderSectionContainer />}>
    <Root>
      <Text>La página solicitada no existe</Text>
      <Image404 alt="La página solicitada no existe" src={`${PUBLIC_URL}/assets/images/404.svg`} />
    </Root>
  </BrandLayout>
)

export default NotFound
