import { customizeError } from '@chilecompra/react-kit'
import { batch } from 'react-redux'
import { makeActionCreator } from '../../config/store/utils'
import {
  editAgreement,
  getAgreement,
  getAgreementConfig,
  createAgreement,
  getEconomicIndicators,
  getRequests,
  updateRequestsStatus,
  countTags,
  downloadRequests,
  getTpErros
} from '../../services/agreementAdjustment'

export const EDIT_AGREEMENT = 'EDIT_AGREEMENT'
export const EDIT_AGREEMENT_ERROR = 'EDIT_AGREEMENT_ERROR'
export const EDIT_AGREEMENT_SUCCESS = 'EDIT_AGREEMENT_SUCCESS'
export const onEditAgreement = makeActionCreator(EDIT_AGREEMENT)
export const onEditAgreementError = makeActionCreator(EDIT_AGREEMENT_ERROR, 'payload')
export const onEditAgreementSuccess = makeActionCreator(EDIT_AGREEMENT_SUCCESS, 'payload')

export const onEditAgreementThunk =
  ({ successCallback, errorCallback, ...payload }) =>
  async dispatch => {
    dispatch(onEditAgreement())
    try {
      const { data } = await editAgreement(payload)
      if (!data.convenioMarcoId || data.code === 404) {
        throw customizeError({ originalError: data })
      }
      return batch(() => {
        successCallback(data)
        dispatch(onEditAgreementSuccess({ data: data.payload, success: data.success }))
      })
    } catch (error) {
      return batch(() => {
        dispatch(
          onEditAgreementError({
            errors: (error?.originalError?.response?.data?.errores || error?.originalError?.errores || []).map(
              item => item.descripcion
            )
          })
        )
        errorCallback()
      })
    }
  }

export const CREATE_AGREEMENT = 'CREATE_AGREEMENT'
export const CREATE_AGREEMENT_ERROR = 'CREATE_AGREEMENT_ERROR'
export const CREATE_AGREEMENT_SUCCESS = 'CREATE_AGREEMENT_SUCCESS'
export const onCreateAgreement = makeActionCreator(CREATE_AGREEMENT)
export const onCreateAgreementError = makeActionCreator(CREATE_AGREEMENT_ERROR, 'payload')
export const onCreateAgreementSuccess = makeActionCreator(CREATE_AGREEMENT_SUCCESS, 'payload')

export const onCreateAgreementThunk =
  ({ successCallback, errorCallback, ...payload }) =>
  async dispatch => {
    dispatch(onCreateAgreement())
    try {
      const { data } = await createAgreement(payload)
      if (!data.convenioMarcoId || data.code === 404) {
        throw customizeError({ originalError: data })
      }
      return batch(() => {
        successCallback(data)
        dispatch(onCreateAgreementSuccess({ data: data.payload, success: data.success }))
      })
    } catch (error) {
      return batch(() => {
        dispatch(
          onCreateAgreementError({
            errors: (error?.originalError?.response?.data?.errores || error?.originalError?.errores || []).map(
              item => item.descripcion
            )
          })
        )
        errorCallback()
      })
    }
  }
export const GET_AGREEMENT = 'GET_AGREEMENT'
export const GET_AGREEMENT_ERROR = 'GET_AGREEMENT_ERROR'
export const GET_AGREEMENT_SUCCESS = 'GET_AGREEMENT_SUCCESS'
export const onGetAgreement = makeActionCreator(GET_AGREEMENT)
export const onGetAgreementError = makeActionCreator(GET_AGREEMENT_ERROR, 'payload')
export const onGetAgreementSuccess = makeActionCreator(GET_AGREEMENT_SUCCESS, 'payload')
export const onGetAgreementThunk =
  ({ callback, loading, ...payload }) =>
  async dispatch => {
    loading()
    dispatch(onGetAgreement(payload.id))
    try {
      const { data } = await getAgreement()
      const agreement = data.find(a => {
        return parseInt(a.idConvenioMarco) === parseInt(payload.id)
      })
      dispatch(
        onGetAgreementSuccess({
          agreement
        })
      )
      callback(agreement)
    } catch (error) {
      callback(null)
      return dispatch(onGetAgreementError(true))
    }
  }

export const GET_AGREEMENT_CONFIG = 'GET_AGREEMENT_CONFIG'
export const GET_AGREEMENT_CONFIG_ERROR = 'GET_AGREEMENT_CONFIG_ERROR'
export const GET_AGREEMENT_CONFIG_SUCCESS = 'GET_AGREEMENT_CONFIG_SUCCESS'
export const onGetAgreementConfig = makeActionCreator(GET_AGREEMENT_CONFIG)
export const onGetAgreementConfigError = makeActionCreator(false, 'payload')
export const onGetAgreementConfigSuccess = makeActionCreator(GET_AGREEMENT_CONFIG_SUCCESS, 'payload')
export const onGetAgreementConfigThunk =
  ({ callback, loading, ...payload }) =>
  async dispatch => {
    loading()
    dispatch(onGetAgreementConfig(payload.id))
    try {
      const { data } = await getAgreementConfig({ id: payload.id })
      dispatch(
        onGetAgreementConfigSuccess({
          data
        })
      )
      callback(data)
    } catch (error) {
      callback(error)
      return dispatch(onGetAgreementConfigError(true, error))
    }
  }

export const GET_ECONOMIC_INDICATORS = 'GET_ECONOMIC_INDICATORS'
export const GET_ECONOMIC_INDICATORS_ERROR = 'GET_ECONOMIC_INDICATORS_ERROR'
export const GET_ECONOMIC_INDICATORS_SUCCESS = 'GET_ECONOMIC_INDICATORS_SUCCESS'
export const onGetEconomicIndicators = makeActionCreator(GET_ECONOMIC_INDICATORS)
export const onGetEconomicIndicatorsError = makeActionCreator(false, 'payload')
export const onGetEconomicIndicatorsSuccess = makeActionCreator(GET_ECONOMIC_INDICATORS_SUCCESS, 'payload')
export const onGetEconomicIndicatorsThunk =
  ({ callback, loading }) =>
  async dispatch => {
    loading()
    dispatch(onGetEconomicIndicators())
    try {
      const { data } = await getEconomicIndicators()
      dispatch(
        onGetEconomicIndicatorsSuccess({
          data
        })
      )
      callback(data)
    } catch (error) {
      callback(error)
      return dispatch(onGetEconomicIndicatorsError(true, error))
    }
  }

export const GET_REQUESTS = 'GET_REQUESTS'
export const GET_REQUESTS_ERROR = 'GET_REQUESTS_ERROR'
export const GET_REQUESTS_SUCCESS = 'GET_REQUESTS_SUCCESS'
export const onGetRequests = makeActionCreator(GET_REQUESTS)
export const onGetRequestsError = makeActionCreator(GET_REQUESTS_ERROR, 'payload')
export const onGetRequestsSuccess = makeActionCreator(GET_REQUESTS_SUCCESS, 'payload')
export const onGetRequestsThunk =
  ({ callback, loading, payload }) =>
  async dispatch => {
    loading()
    dispatch(onGetRequests(payload))
    try {
      const { data } = await getRequests(payload)
      dispatch(
        onGetRequestsSuccess({
          data
        })
      )
      callback(data)
    } catch (error) {
      callback(null)
      return dispatch(onGetRequestsError(true))
    }
  }

export const UPDATE_REQUESTS_STATUS = 'UPDATE_REQUESTS_STATUS'
export const UPDATE_REQUESTS_STATUS_ERROR = 'UPDATE_REQUESTS_STATUS_ERROR'
export const UPDATE_REQUESTS_STATUS_SUCCESS = 'UPDATE_REQUESTS_STATUS_SUCCESS'
export const onUpdateRequestStatus = makeActionCreator(UPDATE_REQUESTS_STATUS)
export const onUpdateRequestStatusError = makeActionCreator(UPDATE_REQUESTS_STATUS_ERROR, 'payload')
export const onUpdateRequestStatusSuccess = makeActionCreator(UPDATE_REQUESTS_STATUS_SUCCESS, 'payload')
export const onUpdateRequestStatusThunk =
  ({ callbackSuccess, callbackError, loading, payload }) =>
  async dispatch => {
    loading()
    dispatch(onUpdateRequestStatus(payload))
    try {
      const { data } = await updateRequestsStatus(payload)
      dispatch(
        onUpdateRequestStatusSuccess({
          data
        })
      )
      callbackSuccess(data)
    } catch (error) {
      callbackError(null)
      return dispatch(onUpdateRequestStatusError(true))
    }
  }

export const COUNT_TAGS = 'COUNT_TAGS'
export const COUNT_TAGS_ERROR = 'COUNT_TAGS_ERROR'
export const COUNT_TAGS_SUCCESS = 'COUNT_TAGS_SUCCESS'
export const onCountTags = makeActionCreator(COUNT_TAGS)
export const onCountTagsError = makeActionCreator(COUNT_TAGS_ERROR, 'payload')
export const onCountTagsSuccess = makeActionCreator(COUNT_TAGS_SUCCESS, 'payload')
export const onCountTagsThunk =
  ({ callback, id }) =>
  async dispatch => {
    dispatch(onCountTags(id))
    try {
      const { data } = await countTags({ id })
      dispatch(
        onCountTagsSuccess({
          data
        })
      )
      callback(data)
    } catch (error) {
      callback(null)
      return dispatch(onCountTagsError(true))
    }
  }

export const DOWNLOAD_RESQUEST = 'DOWNLOAD_RESQUEST'
export const DOWNLOAD_RESQUEST_ERROR = 'DOWNLOAD_RESQUEST_ERROR'
export const DOWNLOAD_RESQUEST_SUCCESS = 'DOWNLOAD_RESQUEST_SUCCESS'
export const onDownloadRequest = makeActionCreator(DOWNLOAD_RESQUEST)
export const onDownloadRequestError = makeActionCreator(DOWNLOAD_RESQUEST_ERROR, 'payload')
export const onDownloadRequestSuccess = makeActionCreator(DOWNLOAD_RESQUEST_SUCCESS, 'payload')
export const onDownloadRequestThunk =
  ({ callback, loading, payload }) =>
  async dispatch => {
    loading()
    dispatch(onDownloadRequest(payload))
    try {
      const { data } = await downloadRequests(payload)
      dispatch(
        onDownloadRequestSuccess({
          data
        })
      )
      callback(data)
    } catch (error) {
      callback(null)
      return dispatch(onDownloadRequestError(true))
    }
  }

export const GET_TP_ERRORS = 'GET_TP_ERRORS'
export const GET_TP_ERRORS_ERROR = 'GET_TP_ERRORS_ERROR'
export const GET_TP_ERRORS_SUCCESS = 'GET_TP_ERRORS_SUCCESS'
export const onGetTpErros = makeActionCreator(GET_TP_ERRORS)
export const onGetTpErrosError = makeActionCreator(GET_TP_ERRORS_ERROR, 'payload')
export const onGetTpErrosSuccess = makeActionCreator(GET_TP_ERRORS_SUCCESS, 'payload')
export const onGetTpErrosThunk =
  ({ callback, payload }) =>
  async dispatch => {
    dispatch(onGetTpErros(payload))
    try {
      const { data } = await getTpErros(payload)
      dispatch(
        onGetTpErrosSuccess({
          data
        })
      )
      callback(data)
    } catch (error) {
      callback(null)
      return dispatch(onGetTpErrosError(true))
    }
  }
