import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@chilecompra/react-kit/components'
import { useEffectOnce } from '@chilecompra/react-kit/utils'
import { Card, GridContainer, GridItem, NavigateNextIcon, ShoppingCartIcon, SuccessIcon } from './Home.styles'

/**
 * The MarcoAgreement's component.
 */
const MarcoAgreement = props => {
  const { biddingNumber, id, isEnabled, name, onRedirect, onTicket } = props

  const handleRedirect = () => onRedirect(id)

  useEffectOnce(() => onTicket(id))

  return (
    <GridItem key={id} xs={12} md={3}>
      <Card color="blank" elevation="2" enableBorder height="100%" onClick={handleRedirect}>
        <GridContainer alignItems="center" justifyContent="center">
          <GridItem xs={12} md={2}>
            <ShoppingCartIcon />
          </GridItem>
          <GridItem xs={12} md={9}>
            <Typography fontSize="16px" fontWeight="bold" lineHeight="20px" margin="0 8px">
              {name}
              {isEnabled && <SuccessIcon />}
            </Typography>
            <Typography color="black3" fontSize="14px" lineHeight="18px" margin="4px 8px" variant="body2">
              {biddingNumber}
            </Typography>
          </GridItem>
          <GridItem xs={12} md={1}>
            <NavigateNextIcon />
          </GridItem>
        </GridContainer>
      </Card>
    </GridItem>
  )
}

MarcoAgreement.defaultProps = {
  biddingNumber: '',
  id: null,
  isEnabled: false,
  name: '',
  onRedirect: () => undefined,
  onTicket: () => undefined
}

MarcoAgreement.propTypes = {
  biddingNumber: PropTypes.string,
  id: PropTypes.number,
  isEnabled: PropTypes.bool,
  name: PropTypes.string,
  onRedirect: PropTypes.func,
  onTicket: PropTypes.func
}

export default MarcoAgreement
