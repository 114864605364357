export const REQUEST_AGREEMENT_STATUS = [
  { name: 'Todos', value: '' },
  { name: 'Aprobado', value: 'vigente' },
  { name: 'Error', value: 'error' },
  { name: 'Rechazado', value: 'rechazado' },
  { name: 'Pendiente', value: 'pendiente' },
  { name: 'No vigente', value: 'No vigente' }
]

export const ADJUSTMENT_STATUS = [
  { name: 'Todos', value: '' },
  { name: 'Vigente', value: 'Vigente' },
  { name: 'Pendiente', value: 'Pendiente' },
  { name: 'Rechazado', value: 'Rechazado' },
  { name: 'No vigente', value: 'No vigente' }
]

export const TAGS_STATUS_COLOR = [
  { color: 'success', value: 'vigente' },
  { color: 'warning', value: 'pendiente' },
  { color: 'error', value: 'rechazado' },
  { color: 'default', value: 'no vigente' }
]

export const MONTHS = {
  0: 'Enero',
  1: 'Febrero',
  2: 'Marzo',
  3: 'Abril',
  4: 'Mayo',
  5: 'Junio',
  6: 'Julio',
  7: 'Agosto',
  8: 'Septiembre',
  9: 'Octubre',
  10: 'Noviembre',
  11: 'Diciembre'
}
