// FIXME: This file is just an example, please take it as reference to make your own.

import React from 'react'
import { Link as BaseLink } from 'react-router-dom'
import { Grid as BaseGrid, styled } from '@mui/material'

export const Root = styled('div')`
  display: block;
  height: auto;
  position: relative;
  width: 100%;
`

export const Row = styled(({ margin, padding, ...props }) => <BaseGrid {...props} container />)`
  &.MuiGrid-container {
    margin: ${props => props.margin || 0};
    padding: ${props => props.padding || 0};
    width: 100%;
  }
`

export const Column = styled(({ margin, padding, ...props }) => <BaseGrid {...props} item />)`
  &.MuiGrid-item {
    margin: ${props => props.margin || 0};
    padding: ${props => props.padding || 0};
    width: 100%;
  }
`

export const Logo = styled(({ isDownToSmall, ...props }) => <img {...props} />)`
  height: ${props => (props.isDownToSmall ? '32px' : '42px')};
  pointer-events: none;
  user-select: none;
`

export const Link = styled(BaseLink)`
  margin: 0;
`

export const ReturnDesk = styled('span')`
  &::before {
    content: '<';
    margin-right: 15px;
  }
`
