// FIXME: This file is just an example, please take it as reference to make your own.

import React from 'react'
import { Card as CardBase, SuccessIcon as SuccessIconBase } from '@chilecompra/react-kit/components'
import { Container as ContainerBase, Grid as BaseGrid, styled } from '@mui/material'
import { NavigateNext, ShoppingCart } from '@mui/icons-material'

export const Root = styled('div')`
  padding: 20px 0;
  width: 100%;
`

export const Card = styled(CardBase)`
  &.MuiPaper-root {
    align-items: center;
    cursor: pointer;
    display: flex;
  }
`

export const Content = styled(ContainerBase)`
  &.MuiContainer-root {
    width: 100%;
  }
`

export const GridContainer = styled(({ margin, padding, ...props }) => <BaseGrid container {...props} />)`
  &.MuiGrid-container {
    margin: ${props => props.margin};
    padding: ${props => props.padding};
  }
`

export const GridItem = styled(({ margin, padding, ...props }) => <BaseGrid item {...props} />)`
  &.MuiGrid-item {
    margin: ${props => props.margin};
    padding: ${props => props.padding};
    width: 100%;
  }
`

export const ShoppingCartIcon = styled(ShoppingCart)`
  &.MuiSvgIcon-root {
    border: 2px solid ${props => props.theme.palette.brand.primary};
    border-radius: 100%;
    color: ${props => props.theme.palette.brand.primary};
    font-size: 24px;
    padding: 5px;
  }
`

export const SuccessIcon = styled(SuccessIconBase)`
  &.MuiSvgIcon-root {
    color: ${props => props.theme.palette.brand.success};
    font-size: 16px;
    margin-left: 4px;
  }
`

export const NavigateNextIcon = styled(NavigateNext)`
  &.MuiSvgIcon-root {
    color: ${props => props.theme.palette.brand.primary};
    font-size: 30px;
  }
`
