import {
  GET_MARCO_AGREEMENT_ITEMS,
  GET_MARCO_AGREEMENT_ITEMS_ERROR,
  GET_MARCO_AGREEMENT_ITEMS_SUCCESS,
  GET_TICKET,
  GET_TICKET_ERROR,
  GET_TICKET_SUCCESS
} from './Home.actions'

const homeState = {
  items: {},
  error: null,
  loading: false
}

/**
 * The homeReducer's reducer.
 */
const homeReducer = (state = homeState, { payload, type }) => {
  switch (type) {
    case (GET_MARCO_AGREEMENT_ITEMS, GET_TICKET): {
      return { ...state, loading: true }
    }
    case (GET_MARCO_AGREEMENT_ITEMS_ERROR, GET_TICKET_ERROR): {
      return { ...state, error: payload.error, loading: false }
    }
    case GET_MARCO_AGREEMENT_ITEMS_SUCCESS: {
      return { ...state, items: payload.items, loading: false }
    }
    case GET_TICKET_SUCCESS: {
      return {
        ...state,
        items: {
          ...state.items,
          [payload.id]: {
            ...state.items[payload.id],
            isEnabled: payload.isEnabled
          }
        }
      }
    }
    default: {
      return state
    }
  }
}

export default homeReducer
