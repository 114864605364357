import { makeActionCreator } from '../../config/store/utils'
import { getAgreementConfig } from '../../services/agreementAdjustment'
import { getMarcoAgreementItems } from '../../services/home'

export const GET_MARCO_AGREEMENT_ITEMS = 'GET_MARCO_AGREEMENT_ITEMS'
export const GET_MARCO_AGREEMENT_ITEMS_ERROR = 'GET_MARCO_AGREEMENT_ITEMS_ERROR'
export const GET_MARCO_AGREEMENT_ITEMS_SUCCESS = 'GET_MARCO_AGREEMENT_ITEMS_SUCCESS'
export const onGetMarcoAgreementItems = makeActionCreator(GET_MARCO_AGREEMENT_ITEMS)
export const onGetMarcoAgreementItemsError = makeActionCreator(GET_MARCO_AGREEMENT_ITEMS_ERROR, 'payload')
export const onGetMarcoAgreementItemsSuccess = makeActionCreator(GET_MARCO_AGREEMENT_ITEMS_SUCCESS, 'payload')
export const onGetMarcoAgreementItemsThunk = () => async dispatch => {
  dispatch(onGetMarcoAgreementItems())

  try {
    const { data = {} } = await getMarcoAgreementItems()
    const items = data?.map(item => ({
      id: item.idConvenioMarco,
      name: item.nombreConvenioMarco,
      biddingNumber: item.nroLicitacionPublica,
      isEnabled: false
    }))

    return dispatch(
      onGetMarcoAgreementItemsSuccess({
        items: items.reduce((dictionary, item) => ({ ...dictionary, [item.id]: item }), {})
      })
    )
  } catch (error) {
    return dispatch(onGetMarcoAgreementItemsError({ error }))
  }
}

export const GET_TICKET = 'GET_TICKET'
export const GET_TICKET_ERROR = 'GET_TICKET_ERROR'
export const GET_TICKET_SUCCESS = 'GET_TICKET_SUCCESS'
export const onGetTicket = makeActionCreator(GET_TICKET)
export const onGetTicketError = makeActionCreator(GET_TICKET_ERROR, 'payload')
export const onGetTicketSuccess = makeActionCreator(GET_TICKET_SUCCESS, 'payload')
export const onGetTicketThunk = payload => async dispatch => {
  dispatch(onGetTicket())

  try {
    const { data = {} } = await getAgreementConfig({ id: payload.id })
    const isEnabled = data?.isEnabled === 1
    return dispatch(onGetTicketSuccess({ id: payload.id, isEnabled }))
  } catch (error) {
    return dispatch(onGetTicketError({ error }))
  }
}
