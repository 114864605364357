import React from 'react'
import { useNavigate } from 'react-router-dom'
import { usePathParam, useViewport } from '@chilecompra/react-kit'
import { Button } from '@chilecompra/react-kit/components'
import routes from '../../config/settings/routes'
import { PUBLIC_URL, REACT_APP_MERCADO_PUBLICO_BASE_URL } from '../../config/settings/environment'
import { Column, Link, Logo, ReturnDesk, Root, Row } from './HeaderSection.styles'

/**
 * The HeaderSection's container.
 */
const HeaderSection = () => {
  const navigate = useNavigate()
  const { size } = useViewport()

  const id = usePathParam('id')
  const handleClickBack = () =>
    id !== undefined
      ? navigate(routes.root)
      : window.open(`${REACT_APP_MERCADO_PUBLICO_BASE_URL}/Portal/Modules/Menu/Menu.aspx`, '_top')

  return (
    <Root>
      <Row alignItems="center" padding="10px">
        <Column sm={3} xs={12}>
          <Link to="#">
            <Logo
              alt="Mercado Público"
              isDownToSmall={size.isDownToSmall}
              src={`${PUBLIC_URL}/assets/images/logo_mercadopublico.svg`}
            />
          </Link>
        </Column>
        <Column sm={3} xs={12}>
          <Button variant="outlined" onClick={handleClickBack}>
            <ReturnDesk>{id !== undefined ? 'Volver' : 'Volver al escritorio'}</ReturnDesk>
          </Button>
        </Column>
      </Row>
    </Root>
  )
}

export default HeaderSection
