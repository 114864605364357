import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { putCookie } from '@chilecompra/react-kit'
import { Spinner } from '@chilecompra/react-kit/components'
import { REACT_APP_MERCADO_PUBLICO_BASE_URL } from '../../config/settings/environment'
import { useAccessToken } from '../../modules/hooks/security'
import { Root } from './ProtectByAccessToken.styles'

/**
 * The ProtectByAccessToken's container.
 *
 * TODO: this component will be remove it after handling proper checking of the access token with the server.
 */
const ProtectByAccessToken = props => {
  const { children } = props
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)
  const accessToken = useAccessToken()

  useEffect(() => {
    if (!accessToken) {
      return setError(true)
    }

    putCookie('access_token', accessToken)
    setLoading(false)
  }, [accessToken])

  if (!accessToken) {
    window.location.href = `${REACT_APP_MERCADO_PUBLICO_BASE_URL}/Portal/Modules/Menu/Menu.aspx`
    return null
  }

  if (!error && loading) {
    return (
      <Root>
        <Spinner />
      </Root>
    )
  }

  if (error) {
    return null
  }

  return <>{children}</>
}

ProtectByAccessToken.propTypes = {
  children: PropTypes.node.isRequired
}

export default ProtectByAccessToken
