import { useMemo } from 'react'
import { getCookie, useQuery } from '@chilecompra/react-kit'

/**
 * Custom hook to get the access token.
 */
export const useAccessToken = () => {
  const query = useQuery()

  return useMemo(() => {
    const accessToken = query.get('t') || getCookie('access_token')

    // when the SSO service is down, the access token is a stringify null.
    if (!accessToken || accessToken === 'null') {
      return null
    }

    return accessToken
  }, [])
}
