import { styled } from '@mui/material'

export const Root = styled('div')`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin: 0;
  padding: 0;
  width: 100%;
`

export const Text = styled('h3')`
  color: ${props => props.theme.palette.brand.black1};
  font-size: 22px;
  font-weight: 300;
  margin: 0;
`

export const Image404 = styled('img')`
  width: 50%;
`
