import { factoryService, getJsonRequest, postJsonRequest } from '@chilecompra/react-kit/requests'
import { REACT_APP_API_BASE_URL } from '../config/settings/environment'
import { getCookie } from '@chilecompra/react-kit'

/**
 * Gets the agreements.
 */
export const getAgreement = factoryService(() => {
  return getJsonRequest(`${REACT_APP_API_BASE_URL}/v1/reajusteextraordinario/convenioMarco`)
})

/**
 * Gets Config agreements.
 */
export const getAgreementConfig = factoryService(({ id }) => {
  return getJsonRequest(`${REACT_APP_API_BASE_URL}/v1/reajusteextraordinario/config/${id}`)
})

/**
 * Create the given Config agreement.
 */
export const createAgreement = factoryService(body =>
  postJsonRequest(`${REACT_APP_API_BASE_URL}/v1/reajusteextraordinario/config/create `, {
    body,
    customHeaders: { 'X-XSRF-TOKEN': getCookie('X-XSRF-TOKEN') }
  })
)

/**
 * Edits the given Config agreement.
 */
export const editAgreement = factoryService(body =>
  postJsonRequest(`${REACT_APP_API_BASE_URL}/v1/reajusteextraordinario/config/edit`, {
    body,
    customHeaders: { 'X-XSRF-TOKEN': getCookie('X-XSRF-TOKEN') }
  })
)

/**
 * Gets INE and Central Bank Last Update.
 */
export const getEconomicIndicators = factoryService(() => {
  return getJsonRequest(`${REACT_APP_API_BASE_URL}/v1/reajusteextraordinario/ineAndCcLastUpdate`)
})

/**
 * Gets the requests.
 */
export const getRequests = factoryService(body => {
  return postJsonRequest(`${REACT_APP_API_BASE_URL}/v1/reajusteextraordinario/adminRequestList`, {
    body,
    customHeaders: { 'X-XSRF-TOKEN': getCookie('X-XSRF-TOKEN') }
  })
})

/**
 * Edits agreement's status.
 */
export const updateRequestsStatus = factoryService(params => {
  return postJsonRequest(`${REACT_APP_API_BASE_URL}/v1/reajusteextraordinario/adminRequestList/edit`, {
    body: params.data,
    customHeaders: { 'X-XSRF-TOKEN': getCookie('X-XSRF-TOKEN') }
  })
})

/**
 * Gets count tags.
 */
export const countTags = factoryService(({ id }) => {
  return getJsonRequest(`${REACT_APP_API_BASE_URL}/v1/reajusteextraordinario/countTag/${id}`)
})

/**
 * Download the requests.
 */
export const downloadRequests = factoryService(body => {
  return postJsonRequest(`${REACT_APP_API_BASE_URL}/v1/reajusteextraordinario/adminRequestListDownload`, {
    body,
    customHeaders: {
      'X-XSRF-TOKEN': getCookie('X-XSRF-TOKEN'),
      'Content-Disposition': 'attachment; filename="requestListDownload.csv"',
      Accept: 'application/csv'
    }
  })
})

/**
 * Get TP errors.
 */
export const getTpErros = factoryService(() => {
  return getJsonRequest(`${REACT_APP_API_BASE_URL}/v1/reajusteextraordinario/adminTpList/getTPErrors`)
})
