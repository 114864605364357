// FIXME: This file is just an example, please take it as reference to make your own.

import React from 'react'
import AuthCallbackContainer from '../containers/AuthCallback/AuthCallback.container'
import NotFound from './NotFound'

/**
 * The AuthCallback' page.
 */
const AuthCallback = () => <AuthCallbackContainer fallback={() => <NotFound />} />

export default AuthCallback
