// FIXME: This file is just an example, please take it as reference to make your own.

import React from 'react'
import { Grid as BaseGrid, styled } from '@mui/material'

export const Root = styled(({ children, inIframe, ...props }) => <div {...props}>{children}</div>)`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: auto;
  padding: 0;
  width: 100%;
`

export const Header = styled('div')`
  margin: 0 auto;
  padding: 0;
  width: 100%;

  ${props => props.theme.breakpoints.only('xs')} {
    max-width: 540px;
  }

  ${props => props.theme.breakpoints.only('sm')} {
    max-width: 720px;
  }

  ${props => props.theme.breakpoints.only('md')} {
    max-width: ${props => (props.inIframe ? '1140px' : '960px')};
  }

  ${props => props.theme.breakpoints.only('lg')} {
    max-width: 1140px;
  }

  ${props => props.theme.breakpoints.only('xl')} {
    max-width: 1140px;
  }
`

export const Body = styled(props => <BaseGrid {...props} container />)`
  &.MuiGrid-container {
    display: flex;
    height: auto;
    margin: 0 auto 36px;
    padding: 0;
    width: 100%;

    ${props => props.theme.breakpoints.only('xs')} {
      max-width: 540px;
    }

    ${props => props.theme.breakpoints.only('sm')} {
      max-width: 720px;
    }

    ${props => props.theme.breakpoints.only('md')} {
      max-width: ${props => (props.inIframe ? '1140px' : '960px')};
    }

    ${props => props.theme.breakpoints.only('lg')} {
      max-width: 1140px;
    }

    ${props => props.theme.breakpoints.only('xl')} {
      max-width: 1140px;
    }
  }
`

export const Section = styled(props => <BaseGrid {...props} item />)`
  &.MuiGrid-item {
    margin: 0;
    padding: 0;
  }
`

export const Footer = styled('div')`
  margin: auto 0 0;
  width: 100%;
`
