// FIXME: This file is just an example, please take it as reference to make your own.

import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { PUBLIC_URL } from './config/settings/environment'
import routes from './config/settings/routes'
import AuthCallbackPage from './pages/AuthCallback'
import HomePage from './pages/Home'
import AgreementAdjustmentPage from './pages/AgreementAdjustment'
import NotFoundPage from './pages/NotFound'

/**
 * The Routing' component.
 */

const Routing = () => {
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route caseSensitive element={<AuthCallbackPage />} path={routes.authCallback} />
        <Route caseSensitive element={<HomePage />} path={routes.root} />
        <Route caseSensitive element={<AgreementAdjustmentPage />} path={routes.agreementAdjustment} />
        <Route element={<NotFoundPage />} path="*" />
      </Routes>
    </BrowserRouter>
  )
}

export default Routing
