import React from 'react'
import { Grid as BaseGrid, styled } from '@mui/material'
import {
  Facebook as BaseFacebookIcon,
  Instagram as BaseInstagramIcon,
  LinkedIn as BaseLinkedInIcon,
  Twitter as BaseTwitterIcon,
  YouTube as BaseYouTubeIcon
} from '@mui/icons-material'

export const Root = styled('div')`
  padding: 24px;
  background-color: ${props => props.theme.palette.blue[15]};
  margin-top: 96px;
`

export const Row = styled(props => <BaseGrid {...props} container />)``

export const Column = styled(props => <BaseGrid {...props} item />)``

export const Logo = styled('img')`
  display: block;
  margin: 0 auto 24px;
  max-width: 150px;

  ${props => props.theme.breakpoints.up('md')} {
    margin: 0 0;
  }
`

export const List = styled('ul')`
  list-style: none;
  margin: 0;
  padding: 0;
`

export const Item = styled('li')`
  color: ${props => props.theme.palette.brand.white};
  font-size: 12px;
  line-height: 32px;
  text-align: center;

  &:first-child {
    font-size: 14px;
  }

  &.rrss-container {
    display: flex;
    justify-content: center;
    margin: 16px 0;
  }

  ${props => props.theme.breakpoints.up('sm')} {
    text-align: left;

    &.rrss-container {
      justify-content: flex-start;
    }
  }
`

export const Link = styled('a')`
  color: ${props => props.theme.palette.brand.white};
  text-decoration: underline;

  &:hover {
    color: ${props => props.theme.palette.blue[35]};
  }

  &:active,
  &:focus,
  &:visited {
    color: ${props => props.theme.palette.brand.white};
  }
`

export const FacebookIcon = styled(BaseFacebookIcon)`
  color: ${props => props.theme.palette.brand.white};
  height: 24px;
  width: 24px;
`

export const InstagramIcon = styled(BaseInstagramIcon)`
  color: ${props => props.theme.palette.brand.white};
  height: 24px;
  margin: 0 8px;
  width: 24px;
`

export const LinkedInIcon = styled(BaseLinkedInIcon)`
  color: ${props => props.theme.palette.brand.white};
  height: 24px;
  margin: 0 8px;
  width: 24px;
`

export const TwitterIcon = styled(BaseTwitterIcon)`
  color: ${props => props.theme.palette.brand.white};
  height: 24px;
  margin: 0 8px;
  width: 24px;
`

export const YouTubeIcon = styled(BaseYouTubeIcon)`
  color: ${props => props.theme.palette.brand.white};
  height: 24px;
  margin: 0 8px;
  width: 24px;
`

export const Strong = styled('span')`
  font-family: ${props => props.theme.typography.fontFamily};
  font-weight: bold;
`
