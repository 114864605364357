// FIXME: This file is just an example, please take it as reference to make your own.

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { putCookie, useQueryParam } from '@chilecompra/react-kit'
import { Spinner } from '@chilecompra/react-kit/components'
import { Root } from './AuthCallback.styles'

/**
 * The AuthCallback's container.
 */
const AuthCallback = props => {
  const { fallback } = props
  const navigate = useNavigate()
  const accessToken = useQueryParam('access_token')
  const [error, setError] = useState(false)

  useEffect(() => {
    if (!accessToken) {
      return setError(true)
    }

    putCookie('access_token', accessToken)
    navigate('/')
  }, [])

  if (error) {
    return fallback()
  }

  return (
    <Root>
      <Spinner />
    </Root>
  )
}

AuthCallback.defaultProps = {
  fallback: () => null
}

AuthCallback.propTypes = {
  fallback: PropTypes.func
}

export default AuthCallback
