import React from 'react'
import { Container as ContainerBase, Grid as GridBase, styled } from '@mui/material'

export const Root = styled(ContainerBase)`
  &.MuiContainer-root {
    width: 100%;
  }
`
export const GridContainer = styled(({ margin, padding, ...props }) => <GridBase container {...props} />)`
  &.MuiGrid-container {
    margin: ${props => props.margin};
    padding: ${props => props.padding};
  }
  &.containerFilterInputs {
    .MuiGrid-item {
      width: 20%;
    }
  }
  &.containerSubmitButtonFilter {
    justify-content: end;
  }
  &.containerTable {
    width: 100%;
    overflow-x: scroll;
    .orderableCell {
      :hover {
        cursor: pointer;
      }
      :after {
        content: '';
        display: inline-block;
        border-bottom: 1px solid rgb(159 159 159);
        border-right: 1px solid rgb(159 159 159);
        height: 7px;
        width: 7px;
        -webkit-transform: rotate(47deg);
        -ms-transform: rotate(47deg);
        transform: rotate(47deg);
        margin-left: 10px;
        margin-bottom: 2px;
        transition: transform 1s, margin-bottom 1s;
      }
      &.active {
        :after {
          border-bottom: 1px solid rgb(0 0 0);
          border-right: 1px solid rgb(0 0 0);
        }
        &.orderDesc {
          :after {
            -webkit-transform: rotate(225deg);
            -ms-transform: rotate(225deg);
            transform: rotate(225deg);
            margin-bottom: -3px;
          }
        }
      }
    }
    th {
      white-space: nowrap;
      font-size: 14px;
      color: #2e384d;
      font-weight: 700;
    }
    td {
      white-space: nowrap;
      font-size: 14px;
      color: #2e384d;
    }
    .fKozgr.MuiPaper-root {
      justify-content: center;
    }
  }
  &.containerTableModal {
    overflow-y: scroll;
    max-height: 400px;
    margin: 10px 0;
  }
  &.containerSearch {
    justify-content: end;
    margin-top: 15px;
  }
`

export const GridItem = styled(({ margin, padding, visibility, ...props }) => <GridBase item {...props} />)`
  &.MuiGrid-item {
    margin: ${props => props.margin};
    padding: ${props => props.padding};
    visibility: ${props => props.visibility};
  }
`
export const Title = styled('h2')`
  font-size: 27px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 31px;
  margin: 20px 0;
`
export const Subtitle = styled('h4')`
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 31px;
  margin: 20px 0;
`
export const ContainerSwitch = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
`

export const Content = styled('form')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  .MuiPaper-root.card-alert {
    > * {
      display: flex;
      justify-content: space-evenly;
    }
    .textEconomicIndicator {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      margin-left: 10px;
      &.error {
        color: #e22c2c;
      }
    }
  }
  .MuiPaper-root.errorsTpCard {
    max-height: 300px;
    overflow: auto;
  }
  .MuiPaper-root.cardTrasparent {
    background-color: transparent;
    &.ajuste {
      label {
        height: 32px;
        span {
          font-size: 0.8rem;
        }
      }
    }
  }
`
export const CardText = styled('span')`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #536479;
  span {
    color: #2e384d;
    font-weight: 700;
  }
`

export const Item = styled('li')`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #536479;
  margin-top: 10px;
`

export const Textarea = styled('textarea')`
  height: 80px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #d1d8e0;
  border-radius: 4px;
`

export const Label = styled('span')`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 1.1px;
  text-transform: uppercase;
  color: #768aa2;
`
