import React from 'react'
import BrandLayout from '../components/Layouts/BrandLayout'
import FooterSectionContainer from '../containers/FooterSection/FooterSection.container'
import HeaderSectionContainer from '../containers/HeaderSection/HeaderSection.container'
import AgreementAdjustmentContainer from '../containers/AgreementAdjustment/AgreementAdjustment.container'
import ProtectByAccessToken from '../components/Security/ProtectByAccessToken'
import NotFound from './NotFound'

/**
 * The AgreementAdjustment' page.
 */

const AgreementAdjustment = () => {
  return (
    <ProtectByAccessToken fallback={() => <NotFound />}>
      <BrandLayout footer={<FooterSectionContainer />} header={<HeaderSectionContainer />}>
        <AgreementAdjustmentContainer />
      </BrandLayout>
    </ProtectByAccessToken>
  )
}

export default AgreementAdjustment
