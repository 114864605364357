// FIXME: This file is just an example, please take it as reference to make your own.

import styled from 'styled-components'

export const Root = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
`
