import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Typography } from '@chilecompra/react-kit/components'
import { useEffectOnce } from '@chilecompra/react-kit/utils'
import routes from '../../config/settings/routes'
import { onGetMarcoAgreementItemsThunk, onGetTicketThunk } from './Home.actions'
import { Content, GridContainer, Root } from './Home.styles'
import MarcoAgreement from './Home.marcoAgreement'

/**
 * The Home's container.
 */
const Home = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { items } = useSelector(state => state.home)

  const handleRedirect = id => navigate(routes.agreementAdjustment.replace(/:id/, id))
  const handleTicket = id => dispatch(onGetTicketThunk({ id }))

  useEffectOnce(() => Object.values(items).length === 0 && dispatch(onGetMarcoAgreementItemsThunk()))

  return (
    <Root>
      <Content>
        <GridContainer>
          <Typography fontSize="14px" lineHeight="18px" variant="subtitle">
            Reajuste de precio
          </Typography>
        </GridContainer>
        <GridContainer>
          <Typography fontSize="22px" lineHeight="26px" variant="h1">
            Selecciona un Convenio Marco para revisar un reajuste de precio
          </Typography>
        </GridContainer>
        <GridContainer margin="32px 0 0" spacing={2}>
          {Object.values(items)?.map(item => (
            <MarcoAgreement
              biddingNumber={item.biddingNumber}
              id={item.id}
              isEnabled={item.isEnabled}
              key={item.id}
              name={item.name}
              onRedirect={handleRedirect}
              onTicket={handleTicket}
            />
          ))}
        </GridContainer>
      </Content>
    </Root>
  )
}

export default Home
